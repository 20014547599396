import React, { PureComponent } from 'react'

import Icon from '@pc/components/Icon'
import { helpers, withBasic } from '@common'
import styles from './style.m.scss'

import icon_corner from '@pc/assets/img/icon-corner.png'
const className = helpers.classNames.react(styles)

@withBasic
export default class SKUArea extends PureComponent {
  constructor(props) {
    super()
    this.state = {
      pageIndex: 1,
    }
  }

  goPrev = () => {
    if (this.state.pageIndex === 1) return false
    this.setState({
      pageIndex: this.state.pageIndex - 1,
    })
  }

  goNext = () => {
    const { orderDetails } = this.props
    if (orderDetails) {
      let maxIndex = Math.ceil(orderDetails.size / 2) //最大页数

      if (this.state.pageIndex === maxIndex) return false
      this.setState({
        pageIndex: this.state.pageIndex + 1,
      })
    }
  }

  render() {
    const {
      discountAmount,
      discountOfTotalPrice,
      discountRate,
      originalPrice,
      orderDetails,
    } = this.props
    const { formatPrice, translate, currencyCode } = this.props
    const { pageIndex } = this.state
    const hasDiscount =
      discountOfTotalPrice && discountOfTotalPrice !== originalPrice

    return (
      <section {...className('sku-area')}>
        <div {...className('sku-area-top')}>
          <p>
            <span>{`${translate('订单金额')}(${currencyCode})`}</span>
          </p>
          {hasDiscount ? (
            <div {...className('discount-area')}>
              <p {...className('sku-price')}>
                {formatPrice(discountOfTotalPrice)}
              </p>
              <s {...className('sku-original-price')}>
                {formatPrice(originalPrice)}
              </s>
              {discountRate ? (
                <div {...className('discount-percentage')}>
                  <span>{`-${Math.round((1 - discountRate) * 100)}%`}</span>
                  <Icon icontype="image" src={icon_corner} />
                </div>
              ) : null}
            </div>
          ) : (
            <div {...className('order-price-area')}>
              {formatPrice(originalPrice)}
            </div>
          )}

          <div {...className('sku-area-line')} />
        </div>
        <div {...className('sku-area-bottom')}>
          {orderDetails &&
            orderDetails.map((item, index) => {
              let pageIndex = 1
              if ((index + 1) % 2) {
                pageIndex = Math.ceil((index + 1) / 2)
              } else {
                pageIndex = (index + 1) / 2
              }
              return (
                <div
                  {...className(
                    `${
                      pageIndex === this.state.pageIndex ? 'sku-info' : 'hide'
                    }`,
                  )}
                  key={item.get('unitPrice')}
                >
                  <div {...className('sku-name')}>{item.get('skuName')}</div>
                  <div {...className('sku-price')}>
                    <span>{translate('单价')}</span>
                    <span />
                    <span>
                      {currencyCode} {formatPrice(item.get('unitPrice'))}
                    </span>
                  </div>
                  <div {...className('sku-quantity')}>
                    <span>{translate('数量')}</span>
                    <span />
                    <span>{formatPrice(item.get('qty'))}</span>
                  </div>
                </div>
              )
            })}
          {orderDetails && orderDetails.length < 3 ? null : (
            <div {...className('sku-list-switch')}>
              <span
                {...className('prev', {
                  disable: pageIndex === 1,
                })}
                onClick={this.goPrev}
              >
                <Icon icontype="svg" name="prevPage" />
              </span>
              <span
                {...className('next', {
                  disable:
                    pageIndex ===
                    Math.ceil((orderDetails ? orderDetails.size : 2) / 2),
                })}
                onClick={this.goNext}
              >
                <Icon icontype="svg" name="nextPage" />
              </span>
            </div>
          )}
        </div>
      </section>
    )
  }
}
