import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { Layout, Modal } from 'antd'
import BizTracker from '@pc/common/tracker/bizTracker'
import Icon from '@pc/components/Icon'
import ContentWrapper from '@pc/components/ContentWrapper'
import { Button } from '@pc/components/Button'
import SKUArea from './components/SKUArea'
import RiskCodeFormPc from './components/RiskCodeForm/index.pc'
import CouponBar from './components/CouponBar'
import { ModalTitle } from '@pc/components/ModalTitle'
import { globalActionCreator } from '@pc/common/globalActions'
import { actionCreator } from './store'
import { actionCreator as loginActionCreator } from '../login/store'
import { helpers, withBasic } from '@common'
import { store } from '../../store'
import Logger, * as elementId from '../../config/logger'
import { Translate } from '../../config/translate'
import {
  getSecurityCacheData,
  getCommonParams,
} from '@/components/Security/utils'
import {
  COUPON_MODAL_FLAG,
  limitCapacityMap,
  SCENARIO_NUMBER_MAP,
  PHONE_COUNTRY_CODE_MAP,
  SECURITY_VERIFICATION_BIZ_TYPE,
  LOGIN_METHOD,
  SLIDER_CONFIG,
  FACE_CONFIG,
} from '@pc/common/macro'
import styles from './style.m.scss'
import icon_creditAvailable from '../../assets/img/creditAvailable.png'
import icon_discount from '../../assets/img/icon_discount.png'

import LoginPasswordForm from './components/LoginPasswordForm'
import instructions_img from '../../assets/img/pc_instructions.png'

import { getApiBaseUrl } from '@pc/config/utils'
import { getSmDeviceData, ALDeviceSDKInstance } from '@/components/device-sdk'
import loggerConfig from './logger.config'
import { dynamic } from 'umi'
import { SecurityVerificationPc as SecurityVerification } from '@/components/SecurityVerification/index.pc'
import { PayVerification } from '@pc/components/PayVerification'

import withPerfCollect from '@/components/withPerfCollect'

const { storage, URL, getBizEnv } = helpers
const { Content } = Layout
const className = helpers.classNames.react(styles)

const PaymentDetails = dynamic(() => import('./components/PaymentDetails'))
const RepaymentDateList = dynamic(() =>
  import('./components/RepaymentDateList').then((res) => res.RepaymentDateList),
)
const PaymentProcess = dynamic(() =>
  import('../login/components/PaymentProcess'),
)
const PeriodList = dynamic(() =>
  import('./components/PeriodList').then((res) => res.PeriodList),
)
const CouponListContainer = dynamic(() =>
  import('./components/CouponListContainer'),
)
const DownpaymentRateListContainer = dynamic(() =>
  import('./components/DonwpaymentRateContainer'),
)

@withBasic
class OrderDetailsPc extends PureComponent {
  constructor() {
    super()
    this.saLogger = Logger.getInstance()
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { basicDialog } = this.props
    const dialogType = nextProps.basicDialog.get('dialogType')
    const dialogContent = nextProps.basicDialog.get('content')
    const shouldShowDialog =
      !basicDialog.get('show') && nextProps.basicDialog.get('show')

    shouldShowDialog && this.controlGlobalDialog(dialogType, dialogContent)
    // HACK: 支付密码组件没有开发pc版，需要临时兼容样式
    if (nextProps.showPayVerificationModal) {
      document.getElementById('root').style.fontSize = '100px'
    } else {
      document.getElementById('root').removeAttribute('style')
    }
  }

  clickBanner = () => {
    const { orderDetailBanner } = this.props
    // 存在跳转链接就跳
    if (orderDetailBanner && orderDetailBanner.jumpUrl) {
      window.location.href = orderDetailBanner.jumpUrl
    }
    this.saLogger.onPageClick(elementId.CLICK_BANNER_ORDER_CONFIRM)
  }

  controlGlobalDialog(dialogType, dialogContent) {
    const { cancelOldPayment, showFullPayDialog, translate, currencyCode } =
      this.props

    //如果弹窗类型是设置全款
    if (dialogType === 'setFullPay') {
      showFullPayDialog(dialogContent)
    } else if (dialogType === 'cancelOldPayment') {
      const formerPaymentObj = storage.get('formerPayment')
      // const { formerDownPayment, formerPeriod } = storage.get('formerPayment')

      const content = (
        <div {...className('cancel-order-tips')}>
          <div>
            <p>{translate('旧的付款将被取消：')}</p>
            <p>
              {translate('首付：')}
              {currencyCode}{' '}
              {formerPaymentObj && formerPaymentObj.formerDownPayment}
            </p>
            <p>
              {translate('分期数：${period}期', {
                period: formerPaymentObj && formerPaymentObj.formerPeriod,
              })}
            </p>
          </div>
        </div>
      )
      cancelOldPayment(content)
    }
  }

  getBasicPopupTitle = () => {
    const { basicPopup, translate, couponModalFlag } = this.props
    const popupName = basicPopup.get('name')

    if (popupName === 'planDetails') {
      return translate('还款计划')
    }

    if (popupName === 'paymentRateList') {
      return translate('请选择首付')
    }

    if (popupName === 'couponList') {
      return couponModalFlag === COUPON_MODAL_FLAG.INSTRUCTION
        ? translate('规则')
        : translate('优惠券')
    }
  }

  getBasicPopupLeft = () => {
    const { basicPopup, couponModalFlag, setCouponModalFlag } = this.props
    const popupName = basicPopup.get('name')
    if (popupName === 'couponList') {
      if (couponModalFlag === COUPON_MODAL_FLAG.INSTRUCTION) {
        return (
          <Icon
            onClick={() => setCouponModalFlag(COUPON_MODAL_FLAG.COUPON)}
            icontype="svg"
            name="return"
            {...className('coupon_modal_title_icon')}
          />
        )
      } else {
        return (
          <Icon
            onClick={() => setCouponModalFlag(COUPON_MODAL_FLAG.INSTRUCTION)}
            icontype="image"
            src={instructions_img}
            {...className('coupon_modal_title_icon')}
          />
        )
      }
    } else {
      return null
    }
  }

  getBasicPopupContent = (discountDPprice, discountDPrate, hasDPdiscount) => {
    const { basicPopup, orderId } = this.props
    const popupName = basicPopup.get('name')

    if (popupName === 'planDetails') {
      this.saLogger.click({
        ...loggerConfig.click_repayment_plan,
        ALpayOrderID: orderId,
      })
      return <PaymentDetails platform="pc" />
    }

    if (popupName === 'paymentRateList') {
      return (
        <DownpaymentRateListContainer
          discountDPprice={discountDPprice}
          discountDPrate={discountDPrate}
          hasDPdiscount={hasDPdiscount}
          platform="pc"
        />
      )
    }

    if (popupName === 'couponList') {
      return <CouponListContainer platform="pc" />
    }
  }

  getBasicPopupStyle = () => {
    const { basicPopup } = this.props
    const popupName = basicPopup.get('name')

    if (popupName === 'planDetails') {
      return 'planDetail-modal'
    }

    if (popupName === 'paymentRateList') {
      return 'dpRateList-modal'
    }

    if (popupName === 'couponList') {
      return 'couponList-modal'
    }
  }

  // 关闭pop弹窗 只有pc端的关闭弹窗会走到这里来 其他的不会
  handleClosePop = () => {
    const {
      basicPopup,
      prevCouponId,
      curCouponId,
      getOrderInfo,
      curPeriod,
      downpaymentRate,
      closePopup,
    } = this.props
    const popupName = basicPopup.get('name')
    closePopup()
    // 如果是优惠券弹窗 并且当前和打开时候的优惠券不一样 就重新获取分期信息
    if (popupName === 'couponList') {
      this.saLogger.onPageClick(loggerConfig.coupon_close)
      if (prevCouponId !== curCouponId) {
        getOrderInfo(curPeriod, downpaymentRate, curCouponId)
      }
    }
  }

  async componentDidMount() {
    const {
      getOrderInfo,
      history,
      saveEnterTime,
      saveOrderParam,
      countryCode,
      getPublicConfig,
      handleGeneratePaymentID,
    } = this.props
    const { state } = history.location
    handleGeneratePaymentID() // 生成支付ID
    // getBillDate()
    BizTracker.enter({ sn: 390004 })
    saveEnterTime()
    // 登录页面从url取的分期数通过路由参数传递
    let curPeriod = state ? state.curPeriod : null
    // 由于放量老系统跳转新系统路由参数丢失，需要重新从url取分期数
    if (!curPeriod) {
      curPeriod = helpers.URL.getParam('periods')
    }
    await getOrderInfo(curPeriod ? curPeriod : null)
    saveOrderParam(curPeriod ? curPeriod : null, countryCode)
    this.getAndReportConfig()
    getPublicConfig()
  }

  getAndReportConfig = async () => {
    const methodToken = helpers.URL.getParam('loginMethodToken')
    const { data } = await this.props.dispatch(
      globalActionCreator.globalGetReportConfig(methodToken),
    )
    const securityCacheParams = getSecurityCacheData()
    const loginType =
      helpers.URL.getParam('loginType') || securityCacheParams.loginType
    this.saLogger.onPageView({
      ...loggerConfig.page_view,
      refNo: this.props.refNo,
      gatewayMerchantId: data?.gatewayMerchantId,
      gatewayMerchantName: data?.gatewayMerchantName,
      appName: data?.appName,
      Aku_channelName: LOGIN_METHOD[loginType],
    })
  }

  componentWillUnmount() {
    BizTracker.leave()
  }

  // 获取使用优惠券之后的优惠金额
  getDiscountPrice = (coupons) => {
    if (coupons) {
      const discountAmount = coupons.get('discountAmount')
      const discountOfTotalPrice = coupons.get('discountOfTotalPrice')
      return { discountOfTotalPrice, discountAmount }
    }
    return {}
  }

  // 手机号脱敏 手机号从url获取 如果获取不到就从localStorage 拿
  phoneDesensitization = () => {
    const phone = (URL.allParam || {}).phone || storage.get('phoneNumber')
    return phone ? `${phone.slice(0, 2)} **** ${phone.slice(-4)}` : phone
  }

  // 安全组件请求报错 errorInfo 有两种情况 一种是接口的response 一种是Error 实例
  handleSecurityVerificationRequestError = ({ error, type }) => {
    const { securityVerificationLogout, securityVerificationOnClose } =
      this.props
    this.saLogger.click({
      ...loggerConfig.confirm_payment,
      code: error.errCode,
      Aku_msg: error.errMsg,
    })
    if (error.errCode === '401') {
      securityVerificationOnClose()
      securityVerificationLogout()
    }
  }

  render() {
    const {
      plans,
      curPeriod,
      orderDetails,
      currencyCode,
      curPayment,
      balance,
      downpaymentRate,
      curMonthlyPay,
      curPaymentStrategy,
      countryCode,
      languageCode,
      basicPopup,
      operationId,
      insufficientLimit,
      periodOfInterestFree,
      showDownPaymentRate,
      orderButtonDisabled,
      downPayment,
      orderDetailBanner,
      couponModalFlag,
      coupons,
      securityVerificationVisible,
      securityVerificationOnSuccess,
      securityVerificationOnRejected,
      securityVerificationOnClose,
      showSmallPayController,
      isSmallPay,
      paymentPasswordVerificationSuccess,
      paymentPasswordVerificationFail,
      paymentPasswordVerificationClose,
      paymentPasswordReportDeviceData,
      paymentPasswordGetSmDeviceData,
      showPayVerificationModal,
      clickPayTime,
      payPwdBusinessId,
    } = this.props
    const {
      formatPrice,
      translate,
      choosePeriod,
      openAgreement,
      opreateBeforePlaceOrder,
      showPlanDetails,
      postRisckCheckCaptcha,
      showPyamentRateList,
      showFullpayment,
      loginBeforePlaceOrder,
      countryId,
      userSetRepaymentDate,
    } = this.props
    // 使用优惠券之后的折扣
    const { discountAmount, discountOfTotalPrice, discountRate } =
      this.getDiscountPrice(coupons)
    const originalPrice = plans.get('price')
    const hasDiscount =
      discountOfTotalPrice && discountOfTotalPrice !== originalPrice

    const riskInstallmentResult = plans.get('riskInstallmentResult')

    const discountDPprice = plans.getIn([
        'discountPriceOfDownPaymentRate',
        downpaymentRate,
      ]),
      discountDPrate = plans.getIn([
        'discountOfDownPaymentRate',
        downpaymentRate,
      ]),
      hasDPdiscount =
        discountDPprice && discountDPprice !== downPayment && curPeriod !== '0'

    return [
      <ContentWrapper key={'detail'} {...className('pc-container')}>
        <Content {...className('pc-container-content')}>
          <SKUArea
            {...{
              discountAmount,
              discountOfTotalPrice,
              discountRate,
              originalPrice,
              orderDetails,
            }}
          />
          <fieldset {...className('order-detials-area')}>
            {countryCode === 'ID' &&
            orderDetailBanner &&
            orderDetailBanner.imagePc ? (
              <section
                {...className('top-banner-pc')}
                onClick={this.clickBanner}
              >
                <img src={orderDetailBanner.imagePc} alt="" />
              </section>
            ) : null}

            <section {...className('downpayment-area')}>
              <h3
                {...className('area-title')}
                onClick={() => {
                  //1.只有用户额度充足的情况下，才能选择首付比例
                  //2.部分0首付用户不允许选择首付比例，因为部分0首付用户后台只返回了0首付选项，强制0首付
                  riskInstallmentResult === limitCapacityMap.LIMIT_SUFFICIENT &&
                    showDownPaymentRate &&
                    showPyamentRateList()
                }}
              >
                <span {...className('downpayment-icon font-lato-bol')}>
                  {curPeriod === '0'
                    ? '100%'
                    : `${parseInt(downpaymentRate * 100, 10)}%`}
                </span>
                <span>{translate('首付')}</span>
                <Icon
                  {...className('downpayment-icon-right')}
                  icontype="svg"
                  name="arrow-right-dark"
                />
              </h3>
              {hasDPdiscount ? (
                <p
                  {...className(
                    'downpayment-amount downpayment-discount-wrapper',
                  )}
                >
                  <span>{currencyCode}</span>
                  <span {...className('fadeIn font-lato-medium')}>
                    {!plans.size ? null : formatPrice(discountDPprice)}
                  </span>
                  <s {...className('discount-price')}>
                    {currencyCode}
                    {formatPrice(downPayment)}
                  </s>
                  {discountDPrate ? (
                    <i {...className('icon-discount-wrapper')}>
                      <Icon
                        {...className('icon-discount')}
                        icontype="image"
                        src={icon_discount}
                      />
                      <span>{`-${Math.round(
                        (1 - discountDPrate) * 100,
                      )}%`}</span>
                    </i>
                  ) : null}
                </p>
              ) : (
                <p {...className('downpayment-amount')}>
                  <span>{currencyCode}</span>
                  <span {...className('fadeIn font-lato-medium')}>
                    {!plans.size ? null : formatPrice(downPayment)}
                  </span>
                </p>
              )}
              <div {...className('quota-area')}>
                <div>
                  <Icon
                    icontype="image"
                    src={icon_creditAvailable}
                    {...className('icon-credit')}
                  />
                  <p>{translate('可使用额度')}</p>
                </div>
                <div>
                  {currencyCode} {formatPrice(balance)}
                </div>
              </div>
              <div {...className('limit-tips')}>
                {riskInstallmentResult &&
                riskInstallmentResult === limitCapacityMap.LIMIT_OUT_CREDIT ? (
                  <p>* {translate('额度不足，请提升额度')}</p>
                ) : null}
              </div>
            </section>

            <section {...className('coupon-code-area')}>
              <CouponBar platform="pc" />
            </section>

            {/* insufficientLimit ? null : (
              <section {...className('content-area payment-rate-area')}>
                <h3 {...className('area-title')}>{translate('请选择首付')}</h3>
                <div {...className('content-body')}>
                  {plans.size > 0 ? (
                    <DownpaymentRateList
                      payments={plans.get('payments')}
                      changePaymentRate={rate => {
                        changePaymentRate(rate)
                        this.saLogger.onPageClick(
                          elementId.CHOOSE_PAYMENT_RATE,
                          rate,
                          pageId
                        )
                      }}
                      curPaymentRate={downpaymentRate}
                      curPeriod={curPeriod}
                      platform="pc"
                    />
                  ) : null}
                </div>
              </section>
            )*/}

            <section {...className('content-area installment-area')}>
              <h3 {...className('area-title')}>{translate('分期付款计划')}</h3>
              <div {...className('content-body')}>
                {!plans.size ? null : (
                  <p {...className('payPlan-info-text font-lato-reg')}>
                    {curPeriod === '0'
                      ? `${currencyCode}${formatPrice(curPayment)}`
                      : `${currencyCode}${formatPrice(
                          curMonthlyPay,
                        )} * ${curPeriod}${translate('月')}`}
                  </p>
                )}
              </div>
            </section>

            <section {...className('content-area period-list-area')}>
              <h3 {...className('area-title')}>
                <span>{translate('分期数（月）')}</span>
                {curPeriod === '0' ? null : (
                  <a {...className('payPlan-link')} onClick={showPlanDetails}>
                    <Icon
                      icontype="svg"
                      name="remind"
                      onClick={showPlanDetails}
                      {...className('icon-reminder')}
                    />
                  </a>
                )}
              </h3>
              <div {...className('content-body')}>
                {!plans.size ? null : (
                  <PeriodList
                    list={curPaymentStrategy}
                    curPeriod={curPeriod}
                    periodOfInterestFree={periodOfInterestFree}
                    choosePeriod={choosePeriod}
                    platform="pc"
                    showFullpayment={showFullpayment}
                  />
                )}
              </div>
            </section>

            {/* 仅对未设置还款日的用户展示，默认选中 ‘25号’ 选项； */}
            {!userSetRepaymentDate && <RepaymentDateList platform="pc" />}

            {insufficientLimit ? (
              <section {...className('content-area applylimit-advertise-area')}>
                {riskInstallmentResult !==
                limitCapacityMap.LIMIT_NO_CREDIT_NEW_USER ? (
                  <p {...className('font-lato-reg')}>
                    <span>
                      {translate(
                        '提升额度享受更多分期支付服务，最高可提升额度',
                      )}
                    </span>
                    <span {...className('font-din-bold')}>
                      {translate('2 juta')}
                    </span>
                  </p>
                ) : (
                  <p {...className('font-lato-reg')}>
                    <span>
                      {translate('申请开通Akulaku分期支付，最高可获额度')}
                    </span>
                    <span {...className('font-din-bold')}>
                      {translate('Rp 25.000.000')}
                    </span>
                  </p>
                )}
                <div {...className('apply-process-container')}>
                  <PaymentProcess
                    hasDownload={true}
                    size="small"
                    platform="pc"
                    {...className('applylimit-process')}
                  />
                </div>
              </section>
            ) : null}

            <footer {...className('order-detail-footer')}>
              <section {...className('content-area noBorder')}>
                <p {...className('service-banks')}>
                  {translate(
                    `由Bank Neo Commerce、Bank Ganesha、Bank Shinhan Indonesia、Bank OCBC NISP、BPR Supra Artapersada、Bank CTBC Indonesia、Bank Permata等银行提供信用贷款服务`,
                  )}
                </p>
                <p {...className('service-agreement')}>
                  <span>{translate('同意')} </span>
                  <a onClick={() => openAgreement(countryCode, languageCode)}>
                    {translate('服务协议')}
                  </a>
                </p>
              </section>
              <Button
                text={translate('确认支付')}
                onClick={() => opreateBeforePlaceOrder(curPeriod)}
                disabled={false}
                loading={orderButtonDisabled}
                platform="pc"
              />
            </footer>
          </fieldset>
        </Content>
      </ContentWrapper>,
      <RiskCodeFormPc
        key={'codeForm'}
        onSuccess={(captcha) => postRisckCheckCaptcha(captcha, operationId)}
      />,
      <LoginPasswordForm
        platform="pc"
        key={'loginPwdForm'}
        onSuccess={(loginPwd) => loginBeforePlaceOrder(loginPwd, curPeriod)}
      />,
      <Modal
        visible={basicPopup.get('show')}
        destroyOnClose={true}
        onCancel={this.handleClosePop}
        centered={true}
        title={
          <ModalTitle
            title={this.getBasicPopupTitle()}
            onClick={this.handleClosePop}
            platform="pc"
            leftContent={this.getBasicPopupLeft()}
            closable={
              !(
                basicPopup.get('name') === 'couponList' &&
                couponModalFlag === COUPON_MODAL_FLAG.INSTRUCTION
              )
            }
          />
        }
        // maskClosable={false}
        key={'popup'}
        footer={[]}
        {...className(this.getBasicPopupStyle())}
      >
        {this.getBasicPopupContent(
          discountDPprice,
          discountDPrate,
          hasDPdiscount,
        )}
      </Modal>,
      showPayVerificationModal ? (
        <PayVerification
          locale={languageCode ? languageCode.toLowerCase() : 'in'}
          env={getBizEnv()}
          apiBaseURL={getApiBaseUrl('/capi')}
          countryId={countryId}
          bizType={SECURITY_VERIFICATION_BIZ_TYPE.PC_ADD_ORDER}
          saParams={{
            pageId: 'openpay03',
            pageName: 'openpay confirm order page',
          }}
          onSuccess={paymentPasswordVerificationSuccess}
          onClose={paymentPasswordVerificationClose}
          onRequestError={paymentPasswordVerificationFail}
          reportDeviceData={paymentPasswordReportDeviceData}
          getSmDeviceData={paymentPasswordGetSmDeviceData}
        />
      ) : null,
      securityVerificationVisible ? (
        <SecurityVerification
          visible={securityVerificationVisible}
          key={'SecurityVerification'}
          locale={languageCode ? languageCode.toLowerCase() : 'in'}
          languageCode={languageCode ? languageCode.toLowerCase() : 'in'}
          env={getBizEnv()}
          bizType={SECURITY_VERIFICATION_BIZ_TYPE.PC_ADD_ORDER}
          deviceId={this.props.device_id}
          deviceUuid={helpers.storage.getSession('deviceData')?.uuid}
          extraParams={{
            ...getCommonParams(),
            amount: hasDiscount ? discountOfTotalPrice : originalPrice,
            isSecretPay: showSmallPayController && isSmallPay ? 0 : 1,
            orderId: this.props.orderId,
            device_id: this.props.device_id,
            order_credit: curMonthlyPay * curPeriod,
            clickPayTime,
            downPayment,
            payPwdBusinessId: payPwdBusinessId || null,
            fullProductId: helpers.storage.getSession('full_product_id'),
          }}
          userId={helpers.storage.getSession('openpay_uid')}
          appBrand="op"
          saParams={{
            pageId: 'openpay03',
            pageName: 'openpay confirm order page',
          }}
          countryCode={`+${PHONE_COUNTRY_CODE_MAP[countryId]}`}
          phoneNumber={this.phoneDesensitization()}
          onSuccess={securityVerificationOnSuccess}
          onRejected={securityVerificationOnRejected}
          onClose={securityVerificationOnClose}
          onRequestError={this.handleSecurityVerificationRequestError}
          apiBaseURL={getApiBaseUrl('/capi')}
          reportDeviceData={{
            pin: paymentPasswordReportDeviceData,
          }}
          getSmDeviceData={paymentPasswordGetSmDeviceData}
          sliderData={SLIDER_CONFIG}
          faceOptions={FACE_CONFIG}
        />
      ) : null,
    ]
  }
}

const mapStateToProps = (state) => ({
  plans: state.getIn(['orderDetail', 'plans']),
  curPeriod: state.getIn(['orderDetail', 'curPeriod']),
  orderDetails: state.getIn(['orderDetail', 'orderDetails']),
  balance: state.getIn(['orderDetail', 'balance']),
  downPayment: state.getIn(['orderDetail', 'downPayment']),
  curPayment: state.getIn(['orderDetail', 'curPayment']),
  downpaymentRate: state.getIn(['orderDetail', 'downpaymentRate']),
  pageId: state.getIn(['orderDetail', 'pageId']),
  curMonthlyPay: state.getIn(['orderDetail', 'curMonthlyPay']),
  curPaymentStrategy: state.getIn(['orderDetail', 'curPaymentStrategy']),
  basicDialog: state.getIn(['orderDetail', 'basicDialog']),
  basicPopup: state.getIn(['orderDetail', 'basicPopup']),
  operationId: state.getIn(['orderDetail', 'operationId']),
  insufficientLimit: state.getIn(['orderDetail', 'insufficientLimit']),
  periodOfInterestFree: state.getIn(['orderDetail', 'periodOfInterestFree']),
  originPrice: state.getIn(['orderDetail', 'originPrice']), //商品真实价格。页面显示价格是在真实价格上向上取整
  showDownPaymentRate: state.getIn(['orderDetail', 'showDownPaymentRate']),
  orderButtonDisabled: state.getIn(['orderDetail', 'orderButtonDisabled']),
  showFullpayment: state.getIn(['orderDetail', 'showFullpayment']),
  orderDetailBanner: state.getIn(['orderDetail', 'orderDetailBanner']),
  billDate: state.getIn(['orderDetail', 'billDate']),
  couponModalFlag: state.getIn(['orderDetail', 'couponModalFlag']),
  curCouponId: state.getIn(['orderDetail', 'curCouponId']),
  prevCouponId: state.getIn(['orderDetail', 'prevCouponId']),
  coupons: state.getIn(['orderDetail', 'coupons']),
  userSetRepaymentDate: state.getIn(['orderDetail', 'userSetRepaymentDate']),
  needSecurityVerification: state.getIn(
    'orderDetail',
    'needSecurityVerification',
  ),
  securityVerificationVisible: state.getIn([
    'orderDetail',
    'securityVerificationVisible',
  ]),
  showSmallPayController: state.getIn([
    'orderDetail',
    'showSmallPayController',
  ]),
  isSmallPay: state.getIn(['orderDetail', 'isSmallPay']),
  orderId: state.getIn(['orderDetail', 'orderId']),
  device_id: helpers.storage.get('deviceId') || 'unknown',
  showPayVerificationModal: state.getIn([
    'orderDetail',
    'showPayVerificationModal',
  ]),
  clickPayTime: state.getIn(['orderDetail', 'clickPayTime']),
  payPwdBusinessId: state.getIn(['orderDetail', 'paymentPasswordOperationId']),
})

const mapDispatchToProps = (dispatch) => ({
  dispatch,
  saveOrderParam(curPeriod, countryCode) {
    dispatch(actionCreator.goSaveOrderParam(curPeriod, countryCode))
  },
  getBillDate() {
    dispatch(actionCreator.goGetBillDate())
  },
  getOrderInfo(curPeriod, downpaymentRate, couponId) {
    // 如果进入订单页设备sdk还没初始化，再获取一次设备信息
    if (!ALDeviceSDKInstance) {
      dispatch(globalActionCreator.globalAsyncGetDeviceData())
    }
    return dispatch(
      actionCreator.goGetOrderInfo(
        curPeriod,
        null,
        null,
        downpaymentRate,
        couponId,
      ),
    )
  },
  saveEnterTime() {
    dispatch(actionCreator.goSaveEnterTime(new Date().getTime()))
  },
  choosePeriod(period, monthlyPay, downpayment, lastMonthlyPay) {
    BizTracker.click({ cn: 5 })
    dispatch(
      actionCreator.doChoosePeriodLogic(
        period,
        monthlyPay,
        downpayment,
        lastMonthlyPay,
      ),
    )
  },
  //下单前置操作
  opreateBeforePlaceOrder(curPeriod) {
    const translate = Translate.getInstance().translate
    const state = store.getState()
    const isSingleFullPayment = state.getIn([
      'orderDetail',
      'plans',
      'isSingleFullPayment',
    ])
    BizTracker.click({ cn: 6 })
    const saLogger = Logger.getInstance()
    const needSecurityVerification = state.getIn([
      'orderDetail',
      'needSecurityVerification',
    ])
    const needRiskChcek = needSecurityVerification && curPeriod !== '0' // 全款支付不需要经过风控验证

    //#region 神策埋点
    const payments = state.getIn(['orderDetail', 'plans', 'payments'])
    const downpaymentRate = state.getIn(['orderDetail', 'downpaymentRate'])
    // 当前选中的分期信息
    const curPeriodInfo = payments
      ?.toJS()
      // eslint-disable-next-line eqeqeq
      [downpaymentRate]?.find((item) => item.periods == curPeriod)
    helpers.storage.setSession('full_product_id', curPeriodInfo?.fullProductId)
    saLogger.click({
      ...loggerConfig.confirm_payment,
      Aku_product_id: curPeriodInfo?.fullProductId,
      skucreditTerm: curPeriod,
    })
    //#endRegion

    // 上报设备指纹信息
    dispatch(
      globalActionCreator.globalSendDeviceData({
        scenario_number: SCENARIO_NUMBER_MAP.ADD_ORDER,
      }),
    )

    if (curPeriod === '0' && isSingleFullPayment) {
      dispatch(
        globalActionCreator.toggleAlertDialog(true, {
          title: translate('提示'),
          content: translate('当前暂时无法提交订单，请稍后再试'),
          footer: [
            {
              text: translate('确认'),
            },
          ],
        }),
      )
      return
    }

    dispatch(actionCreator.goOpreateBeforePlaceOrder({ needRiskChcek }))
  },
  openAgreement(countryCode, languageCode) {
    const saLogger = Logger.getInstance()
    saLogger.onPageClick(loggerConfig.click_to_confirm_agreement)
    BizTracker.click({ cn: 7 })
    location.href = 'https://www.akulaku.com/artikel/akulakupaylater/' // installment_Agreement
    // 20201208 协议页迁移到自己项目下
    // dispatch(
    //   push({
    //     pathname: './terms/serviceAgreement',
    //     search: window.location.search,
    //   }),
    // )
    //   const bizEnv = getBizEnv()
    //   const domain = [
    //     'https://',
    //     bizEnv === 'prod' ? '' : `${bizEnv}-`,
    //     'mall.akulaku.com'
    //   ].join('')
    //   languageCode === 'EN'
    //     ? (window.location.href = `${domain}/v2/terms.html?lang=ph`)
    //     : (window.location.href = `${domain}/v2/terms.html?lang=${countryCode.toLowerCase()}`)
  },
  showCouponList() {
    dispatch(actionCreator.goToggleBasicPopup(true, 'couponList'))
  },
  //查看分期详情
  showPlanDetails() {
    dispatch(actionCreator.goToggleBasicPopup(true, 'planDetails'))
  },
  //查看首付比例列表
  showPyamentRateList() {
    dispatch(actionCreator.goToggleBasicPopup(true, 'paymentRateList'))
  },
  closePopup() {
    dispatch(actionCreator.goToggleBasicPopup(false))
  },
  postRisckCheckCaptcha(captcha, operationId) {
    dispatch(actionCreator.postRiskCaptcha(captcha, operationId, false))
  },
  cancelOldPayment(content) {
    const translate = Translate.getInstance().translate

    dispatch(
      globalActionCreator.toggleAlertDialog(true, {
        title: translate('提示'),
        content,
        footer: [
          {
            text: translate('确认'),
            onPress: () => dispatch(actionCreator.goCancelOrder()),
          },
        ],
      }),
    )
    dispatch(
      actionCreator.goToggleBasicDialog({
        show: false,
        dialogType: '',
        content: '',
      }),
    )
  },
  showFullPayDialog(content) {
    const translate = Translate.getInstance().translate

    dispatch(
      globalActionCreator.toggleAlertDialog(true, {
        title: content ? translate('分期失败') : translate('提示'), //TOFIX: 临时这样写的
        content: content
          ? content
          : translate(
              '基于COVID-19疫情期间您的最新信用评估，很抱歉您不能分期支付此订单，请使用全款支付。当此订单能通过分期支付时，我们会及时通知您。请注意安全和身体健康！',
            ),
        footer: [
          {
            text: translate('我知道了'),
            onPress: () => {
              dispatch(actionCreator.goChooseFullpayOnly())

              const saLogger = Logger.getInstance()
              saLogger.setOpenpayRiskStatus('全款')
            },
          },
        ],
      }),
    )
    dispatch(
      actionCreator.goToggleBasicDialog({
        show: false,
        dialogType: '',
        content: '',
      }),
    )
  },

  loginBeforePlaceOrder(loginPwd, curPeriod) {
    const needRiskChcek = curPeriod !== '0' //全款支付不需要经过风控验证

    dispatch(actionCreator.goLoginBeforePlaceOrder(loginPwd, needRiskChcek))
  },
  setCouponModalFlag(couponModalFlag) {
    dispatch(actionCreator.goSetCouponModalFlag(couponModalFlag))
  },
  // 获取公共配置 暂时是只有是否需要安全组件
  getPublicConfig() {
    dispatch(actionCreator.goGetPublicConfig())
  },
  // 安全组件校验成功
  securityVerificationOnSuccess({ operationId }) {
    const saLogger = Logger.getInstance()
    saLogger.click({
      ...loggerConfig.confirm_operation,
      Aku_operation_id: SECURITY_VERIFICATION_BIZ_TYPE.PC_ADD_ORDER,
      return_code: 1, // 安全组件状态：1-验证通过、2-验证失败
    })
    const isAutoLogin = storage.getSession('autoLoginTimestamp') ? true : false
    dispatch(
      actionCreator.afterSecurityVerification({
        operationId,
        isAutoLogin,
        skipPassword: false,
      }),
    )
  },
  // 安全组件关闭
  securityVerificationOnClose() {
    dispatch(actionCreator.toggleSecurityVerification(false))
  },
  // 安全组件拒绝
  securityVerificationOnRejected(retry) {
    console.log(retry)
    const saLogger = Logger.getInstance()
    saLogger.click({
      ...loggerConfig.confirm_payment,
      code: 'unknown',
      Aku_msg: 'security verification reject',
    })
    saLogger.click({
      ...loggerConfig.confirm_operation,
      Aku_operation_id: SECURITY_VERIFICATION_BIZ_TYPE.PC_ADD_ORDER,
      return_code: 2, // 安全组件状态：1-验证通过、2-验证失败
    })
  },
  // 安全组件登录态没有退出
  securityVerificationLogout() {
    dispatch(loginActionCreator.goSetNeedLogoutToast(true))
    dispatch(globalActionCreator.smartJumpToLoginPage())
  },
  // 支付密码组件校验成功
  paymentPasswordVerificationSuccess({ businessId }) {
    dispatch(
      actionCreator.setPasswordVerificationData({
        paymentPasswordCheckPass: true,
      }),
    )
    dispatch(actionCreator.togglePaymentPasswordVerification(false))
    dispatch(actionCreator.continueRiskCheck({ operationId: businessId }))
  },
  // 支付密码组件校验失败
  paymentPasswordVerificationFail(err) {
    console.log(err)
    const saLogger = Logger.getInstance()
    saLogger.click({
      ...loggerConfig.confirm_payment,
      code: err?.error?.errCode,
      Aku_msg: err?.error?.errMsg,
    })
    // dispatch(globalActionCreator.toggleToast(true, err.errorMsg || err.message))
  },
  // 支付密码组件校验关闭
  paymentPasswordVerificationClose(err) {
    dispatch(actionCreator.__disableOrderButton(false))
    dispatch(actionCreator.togglePaymentPasswordVerification(false))
  },
  handleGeneratePaymentID() {
    dispatch(actionCreator.generatePaymentID())
  },
  paymentPasswordReportDeviceData() {
    // pin码 支付验证上报自研设备信息
    dispatch(
      globalActionCreator.globalSendDeviceData({
        scenario_number: SCENARIO_NUMBER_MAP.PAYMENT_PASSWORD,
      }),
    )
  },
  async paymentPasswordGetSmDeviceData() {
    // pin码 支付验证上报数美设备信息
    const smDeviceData = await getSmDeviceData()
    return smDeviceData
  },
})

export default withPerfCollect()(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(OrderDetailsPc)),
)
