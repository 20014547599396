import React, { PureComponent } from 'react'
import { Modal } from 'antd'
import { connect } from 'react-redux'
import { debounce } from 'lodash'

import { ModalTitle } from '@pc/components/ModalTitle'
import { helpers, withBasic } from '@common'
import { store } from '@pc/store'
import { CodeInput } from '../../../login/components/InputFields'
import Logger, * as elementId from '@pc/config/logger'
import { actionCreator } from '../../store'
import BizTracker from '@pc/common/tracker/bizTracker'
import styles from './style.m.scss'
import { Button } from '@pc/components/Button'

const className = helpers.classNames.react(styles)

@withBasic
class RiskCodeForm extends PureComponent {
  constructor() {
    super()
    this.state = {
      captcha: '',
    }
    this.controls = []
    this.beforeSubmit = this.beforeSubmit.bind(this)
    this.saLogger = Logger.getInstance()
  }

  //保存控件实例到数组中
  register = (instance) => {
    this.controls.push(instance)
  }

  //收集控件value值保存到表单state中
  collect = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    })
  }

  beforeSubmit = () => {
    const { setCaptchaErrorText } = this.props
    //提交表单前，每一个控件应该通过数据校验
    let result, errMsg

    result = this.controls.every((elem) => {
      if (elem.validate(elem.state.value) === false) {
        return true
      } else {
        errMsg = elem.validate(elem.state.value)
        setCaptchaErrorText(errMsg)
        return false
      }
    })

    return result
  }

  submit = () => {
    const { captcha } = this.state
    const { pageId } = this.props
    BizTracker.click({ cn: 17 })
    if (this.beforeSubmit()) {
      this.props.onSuccess(captcha)

      this.saLogger.onPageClick(elementId.RISK_SMS_INPUT, null, pageId)
    }
  }

  //如果控件被销毁，则要重置数组
  removeController = (name) => {
    if (!name) return

    this.controls = this.controls.filter((elem) => {
      if (elem.name !== name) return elem
    })
  }

  componentWillReceiveProps(props) {
    if (props.showRiskCaptchaDialog) {
      BizTracker.expose({
        cn: 14,
      })
    }
  }

  render() {
    const {
      showRiskCaptchaDialog,
      translate,
      setCaptchaErrorText,
      toggleRiskCaptchaDialog,
      sendCode,
    } = this.props
    const { captchaErrorText, sent, countDown, pageId } = this.props

    return (
      <Modal
        visible={showRiskCaptchaDialog}
        centered={true}
        title={
          <ModalTitle
            title={translate('验证码')}
            onClick={() => {
              setCaptchaErrorText('')
              toggleRiskCaptchaDialog(false)
              this.removeController('captcha')
            }}
            platform="pc"
          />
        }
        footer={
          <Button
            text={translate('提交')}
            onClick={debounce(this.submit, 300)}
          />
        }
        width="450px"
      >
        <form {...className('risk-code-form-pc')}>
          <CodeInput
            type="text"
            name="captcha"
            placeholder={translate('请输入验证码')}
            onRegister={this.register}
            onControl={this.collect}
            errText={captchaErrorText}
            validation={{ type: 'code' }}
            btnText={sent ? `${countDown}s` : translate('发送')}
            btnDisable={sent}
            onFocus={() => {
              setCaptchaErrorText('')
              this.saLogger.onPageInput(
                elementId.RISK_SMS_INPUT,
                'begin',
                pageId,
              )
            }}
            onBlur={() => {
              this.saLogger.onPageInput(elementId.RISK_SMS_INPUT, 'end', pageId)
            }}
            onSend={() => {
              BizTracker.click({ cn: 15 })
              this.saLogger.onPageClick(
                elementId.CLICK_SUBMIT_RISK_SMS,
                null,
                pageId,
              )
              sendCode()
            }}
            onChange={(ic) => {
              BizTracker.input({
                key: 'new-risk-verify-code-pc',
                cn: 16,
                ic,
              })
            }}
            required
          />
        </form>
      </Modal>
    )
  }
}

const mapStateToProps = (state) => ({
  showRiskCaptchaDialog: state.getIn(['orderDetail', 'showRiskCaptchaDialog']),
  sent: state.getIn(['orderDetail', 'sent']),
  countDown: state.getIn(['orderDetail', 'countDown']),
  pageId: state.getIn(['orderDetail', 'pageId']),
  captchaErrorText: state.getIn(['orderDetail', 'captchaErrorText']),
})

const mapDispatchToProps = (dispatch) => ({
  setCaptchaErrorText(errText) {
    dispatch(actionCreator.goSetCodeErrorText(errText))
  },
  toggleRiskCaptchaDialog(show) {
    dispatch(actionCreator.toggleRiskCaptchaDialog(show))
  },
  sendCode() {
    const state = store.getState()
    const operationId = state.getIn(['orderDetail', 'operationId'])
    const countryId = state.getIn(['main', 'countryId'])

    dispatch(actionCreator.getRiskCaptcha(operationId, countryId))
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(RiskCodeForm)
